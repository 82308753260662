<style>
.v-dialog__content .v-card__title {
    background-color: white !important;
    color: #001BCA;
}
.txt-titulo{
  font-weight: 100;
   color: #001BCA;

}
</style>
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="txt-titulo text-h5">Tecnicas</span>
          <v-spacer></v-spacer>
           <v-btn class="mr-5" icon  @click="dialog = false">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pl-5 ml-3" cols="6">
                <v-text-field
                label="Tipo de Tecnica"
                v-model="form_lab.tecnica"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col >
                <v-btn
                color="primary"
                :loading="loading6"
                :disabled="loading6"
                outlined
                fab
                dark
                large
                @click="savenewTecnica()">
                <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Tipo de Ensayo</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="item in data_ensayo.tecnicas"
                      :key="'tec_'+item.id">
                        <td width="70%">
                          <v-text-field
                          v-model="item.nombre"
                          ></v-text-field>
                        </td>
                        <td width="30%">
                          <v-btn
                          color="primary"
                          outlined
                          fab
                          x-small>
                          <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                          <v-btn color="error"
                          outlined
                          fab x-small>
                          <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex';
import Service from '../../services/apis';

export default {
  data: () => ({
    dialog: false,
    form_lab: {
      tecnica: '',
    },
    loading6: false,
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  methods: {
    openmodal() {
      this.dialog = true;
    },
    async savenewTecnica() {
      if (this.form_lab.tecnica === '') {
        return;
      }
      this.loading6 = true;
      const nuevolab = await Service.apiToken('POST', 'new-tecnica', this.$ls.storage.token, this.form_lab);
      // this.snack.snackbar = true;
      // this.snack.color = 'success';
      // this.snack.txt = `${this.form_lab.tecnica}  se guardo correctamente`;
      // push al objeto de laboratorios
      this.data_ensayo.tecnicas.push(nuevolab.data);
      this.form_lab.tecnica = '';
      this.loading6 = false;
    },
  },
};
</script>
